
import React, { useState, useEffect } from "react";
import { Dropdown } from 'primereact/dropdown';
import TemplateCard from "../components/TemplateCard";
import Topbar from "../components/Topbar";
import { Link } from 'react-router-dom';
import { getFlowsById } from "../api/Flows";
import { ProgressSpinner } from 'primereact/progressspinner';
import { useMediaQuery } from 'react-responsive'
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { FilterMatchMode } from 'primereact/api';

const colors = [
    "#3FBF81", "#2196F3", "#FFBF66", "#9c27b0", "D2DCE8"
]

export default function MyTemplate() {
    // const [selectedCity, setFilter] = useState(null);
    const filter = [
        { name: 'Active' },
        { name: 'Inactive' },
    ];
    const [loading, setLoading] = useState(false);
    const [flows, setFlows] = useState(null);
    const [filteredFlows, setFilteredFlows] = useState(null);
    const [statusFilter, setStatusFilter] = useState(null);
    const [participantObj, setParticipantObj] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 470px)'
    })

    useEffect(() => {
        const savedValue = JSON.parse(window.localStorage.getItem("auth"));
        getTemplates(savedValue);
        const part = JSON.parse(window.localStorage.getItem("participantList"));
        setParticipantObj(part);


    }, [statusFilter]);

    const getTemplates = async (savedValue) => {


        // localStorage.removeItem("firstLoad");
        await getFlowsById(savedValue?.team.id, statusFilter).then((data) => {
            let sortedArr = []
            data.map((dat) => {
                let a = dat;
                if (a.schedule == 'Custom') {
                    const dayArr = a.customDates.toString();
                    a.customDates = dayArr;
                } else {
                    a.customDates = "Mon - Fri ";
                }
                sortedArr.push(a);
            })
            // setFlows(sortedArr).then(()=>{
            //     setLoading(false);
            // });
            setFlows(sortedArr, () => {
                setLoading(false);
            });

        });

        // await participant(JSON.stringify({ token: savedValue?.token }))

    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        // let _filters = { ...filters };

        // _filters['global'].value = value;

        // setFilters(_filters);
        setLoading(true);
        setGlobalFilterValue(value);
        if (value) {
            let filteredData = flows && flows.filter((data) =>
                data.title
                    .toLowerCase()
                    .includes(value.toLowerCase())
            );
            setFilteredFlows(filteredData);

        } else {
            setFilteredFlows(flows);
        }


        setLoading(false);

    };

    const badgeSelector = (key) => {
        if (key == "meeting_notes") {
            return "Meeting Notes"
        } else if (key == "daily_standup") {
            return "Daily Standup"
        } else if (key == "team_feedback") {
            return "Team Feedback"
        } else if (key == "retrospective") {
            return "Retrospective"
        } else {
            return null;
        }
    }


    return (
        <>

            <div>
                <div> <Topbar title={"Workflows"} subtitle={" "} /></div>
            </div>
            <div className="card flex justify-content-start mx-6 my-4">
                <Dropdown value={statusFilter} onChange={(e) => setStatusFilter(e.value)} options={filter} optionLabel="name"
                    showClear placeholder="Filter" className="w-full md:w-14rem" />
                <IconField iconPosition="left" className="mx-3">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </IconField>
            </div>

            <div class="flex flex-wrap mx-5 my-4">
                {
                    loading == false && flows ? (
                        <>
                            {flows.length > 0 ? (

                                <>
                                    {filteredFlows ? filteredFlows.map((flow, index) => (
                                        <div class="mx-3 my-3" style={isDesktopOrLaptop ? { width: '47%', minWidth: '30rem', minHeight: '5rem' } : { width: '100%', }}>
                                            <div>
                                                <Link to={flow.id} style={{ textDecoration: 'none' }}>
                                                    <TemplateCard title={flow.title} badge={badgeSelector(flow.key)} in={index} datetime={flow.customDates + " at " + flow.time} timezone={"Timezone: " + flow.timezone} participants={flow?.participants} participantObj={participantObj} />
                                                </Link>
                                            </div>
                                        </div>
                                    )) : flows.map((flow, index) => (
                                        <div class="mx-3 my-3" style={isDesktopOrLaptop ? { width: '47%', minWidth: '30rem', minHeight: '5rem' } : { width: '100%', }}>
                                            <div>
                                                <Link to={flow.id} style={{ textDecoration: 'none' }}>
                                                    <TemplateCard title={flow.title} badge={badgeSelector(flow.key)} in={index} datetime={flow.customDates + " at " + flow.time} timezone={"Timezone: " + flow.timezone} participants={flow?.participants} participantObj={participantObj} />
                                                </Link>
                                            </div>
                                        </div>
                                    ))}

                                    {/* <div class="mx-3 my-3" style={{ width: '45rem', minWidth: '30rem', minHeight: '5rem' }}>
                                <div>
                                    <Link to={'4'} style={{ textDecoration: 'none' }}>
                                        <TemplateCard title={"Meeting notes meeting X"} badge={"Meeting Notes"} datetime={"Monday 28th of April 2024 09.34"} timezone={"Timezone: Europe/Stockholm"} />
                                    </Link>
                                </div>
                            </div> */}
                                </>
                            ) : (
                                <div class="flex align-items-center justify-content-center flex-wrap" >
                                    <div class="mx-3 my-3" >
                                        <h1>No templates yet </h1>
                                    </div>
                                </div>


                            )}
                        </>


                    ) : (
                        <div class="flex align-items-center justify-content-center flex-wrap" >

                            <h1><ProgressSpinner /> </h1>

                        </div>
                    )
                }

            </div>

        </>

    )
}
