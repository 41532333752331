import React, { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem, menuClasses } from 'react-pro-sidebar';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import Logo from '../images/logo.png';
import Logo2 from '../images/logo2.png';
import Icon from '../images/Icon.png';
import CropLogo from '../images/croppedlogo.png';
import { Avatar } from "primereact/avatar";
import { flowsPresentById } from "../api/Flows";
import { Button } from 'primereact/button';

const themes = {
    light: {
        sidebar: {
            backgroundColor: '#FFFFFF',
            color: '#607489',
        },
        menu: {
            menuContent: '#FFFFFF',
            icon: '#0098e5',
            hover: {
                backgroundColor: '#c5e4ff',
                color: '#44596e',
            },
            disabled: {
                color: '#9fb6cf',
            },
        },
    },
};




export default function Settings(props) {

    const [activePage, setActivePage] = useState(null);
    // const [collapsed, setCollapsed] = useState(false);
    const collapsed = props.collapsed;
    const [flows, setFlows] = useState(null);
    const navigate = useNavigate();
    const part = JSON.parse(window.localStorage.getItem("participantList"));
    const auth = JSON.parse(window.localStorage.getItem("auth"));

    useEffect(() => {
        getTemplates();
    }, [JSON.parse(window.localStorage.getItem("auth"))]);

    const getTemplates = async () => {

        const flow = await flowsPresentById(JSON.parse(window.localStorage.getItem("auth"))?.team.id);
        if (flow == true) {
            setFlows(true);
        } else {
            setFlows(false);
        }
    };

    function handleActive(event) {
        if (!event.target.classList.value.includes("active")) {
            event.target.classList.toggle('active');
            if (activePage)
                activePage.classList.remove("active");
            setActivePage(event.target);

        }
    }

    const handleLogout = () => {

        localStorage.removeItem("participantList");
        localStorage.removeItem("auth");
        localStorage.removeItem("channelList");
        // localStorage.removeItem("firstLoad");
        navigate("/");
        window.location.reload();
    };

    const handleCollapsed = () => {

        props.setCollapsed();
    };


    return (


        <div>

            <Sidebar
                collapsed={props.collapsed}
                rootStyles={{
                    // position: '-webkit-sticky',
                    // position: 'sticky',
                    // top: 0,

                    height: "100vh",
                    width: '30vh',
                    color: themes['light'].sidebar.color,

                    backgroundColor: '#FFFFFF'
                }}>


                <Menu menuItemStyles={{
                    root: {
                        fontSize: '16px',
                        fontWeight: 400,
                        paddingLeft: 5,
                        paddingRight: 15,
                        borderRadius: 15,
                    },
                    SubMenuExpandIcon: {
                        color: '#b6b7b9',
                    },
                    button: {
                        [`&.${menuClasses.disabled}`]: {
                            color: themes['light'].menu.disabled.color,
                        },
                        [`&.${menuClasses.active}`]: {
                            backgroundColor: '#E9EFFF',
                            color: '#1B59F9',
                            borderRadius: 15
                        },
                        '&:hover': {
                            backgroundColor: '#00458b',
                            color: '#FFFFFF',
                            borderRadius: 15
                        },

                    },
                    label: ({ open }) => ({
                        fontWeight: open ? 600 : undefined,
                    }),
                }}>
                    {collapsed ? (
                        <div>
                            <Button
                                onClick={() => handleCollapsed()}

                                link
                                style={{
                                    textDecoration: 'none',
                                    paddingLeft: '40%'
                                }}
                            >
                                <div>
                                    <i className="pi pi-bars" style={{ fontSize: '1rem' }}></i>
                                </div></Button>
                            {/* <img alt="Card" src={CropLogo} width={'100%'} style={{ zIndex: 15 }} /> */}
                            <div className=" flex m-2 justify-content-center align-content-center">
                                <img alt="Card" src={Icon} width={'60%'} style={{ zIndex: 15, }} />
                            </div>
                        </div>
                    ) : (
                        <div className="grid  justify-content-center align-content-center ">
                            <div className="col-10" style={{ backgroundColor: '#FFFFFF' }}>
                                <img alt="Card" src={Logo2} width={'110%'} style={{ zIndex: 15 }} />
                            </div>
                            <div className="col-2 flex justify-content-center align-content-right " style={{ backgroundColor: '#FFFFFF' }}>
                                <Button
                                    onClick={() => handleCollapsed()}
                                    size="large"
                                    link
                                    style={{
                                        textDecoration: 'none',

                                    }}
                                >
                                    <div className=" ">
                                        <i className="pi pi-bars"></i>
                                    </div></Button>
                            </div>

                        </div>
                    )}

                    {/* <Button
                        onClick={() => handleCollapsed()}
                        link
                        style={{
                            justifySelf: 'right',
                            fontWeight: 400,
                            paddingLeft: collapsed ? '40%' : '90%',
                            paddingRight: '0',
                            borderRadius: 0,
                            textDecoration: 'none'
                        }}
                    >
                        <div className=" flex justify-content-right align-content-right">
                            <i className="pi pi-bars" style={{ fontSize: '1rem' }}></i>
                        </div></Button>
                    {collapsed ? (
                        <>
                            <img alt="Card" src={CropLogo} width={'100%'} style={{ zIndex: 15 }} />

                        </>

                    ) : (<img alt="Card" src={Logo} width={'110%'} style={{ zIndex: 15 }} />)} */}

                    {!collapsed && (
                        <div style={{ padding: '0 10px', fontSize: '16px', fontWeight: 200, paddingLeft: 25, }}>
                            <p>Menu</p>
                        </div>
                    )}
                    {
                        flows == true ? (
                            <>
                                <MenuItem active={window.location.pathname === "/workflows"} component={<Link to="/workflows" onClick={handleActive} />} ><span style={{ width: 30 }} className="pi pi-chart-line"></span>{"  " + " Workflows"}</MenuItem>
                                <MenuItem active={window.location.pathname === '/templates'} component={<Link to='/templates' onClick={handleActive} />}><span style={{ width: 30 }} className="pi pi-file"></span>{"  " + " Templates"}</MenuItem>
                            </>
                        ) : (
                            <>
                                <MenuItem active={window.location.pathname === '/templates'} component={<Link to='/templates' onClick={handleActive} />}><span style={{ width: 30 }} className="pi pi-file"></span>{"  " + " Templates"}</MenuItem>
                                <MenuItem active={window.location.pathname === "/workflows"} component={<Link to="/workflows" onClick={handleActive} />} ><span style={{ width: 30 }} className="pi pi-chart-line"></span>{"  " + " Workflows"}</MenuItem>
                            </>
                        )
                    }

                    {/* <MenuItem active={window.location.pathname === "/billing"} component={<Link to="/billing" onClick={handleActive} />}> <span style={{ width: 30 }} className="pi pi-dollar"></span>{"  " + " Billing"}</MenuItem> */}
                    <MenuItem active={window.location.pathname === "/logs"} component={<Link to="/logs" onClick={handleActive} />}> <span style={{ width: 30 }} className="pi pi-code"></span>{"  " + " Logs"}</MenuItem>
                    {!collapsed && (

                        <div style={{ padding: '0 10px', fontSize: '16px', fontWeight: 200, paddingLeft: 25, }}>
                            <p>
                                Account
                            </p>
                        </div>
                    )}
                    <MenuItem active={window.location.pathname === "/profile"} component={<Link to="/profile" onClick={handleActive} />} ><span style={{ width: 30 }} className="pi pi-cog"></span>{"  " + " Profile"}  </MenuItem>
                    <MenuItem active={window.location.pathname === "/help"} component={<Link to="/help" onClick={handleActive} />}> <span style={{ width: 30 }} className="pi pi-info-circle"></span>{"  " + " Help & Center"}</MenuItem>
                    <div style={{ padding: '0', marginTop: '15vh' }}>
                        <Link to="/profile" style={{ textDecoration: 'none', color: 'black' }}>
                            <div className="px-3 py-3 flex align-content-center flex-col">
                                {part && auth && part[auth.channel.slackUserId].real_name[0] ? (
                                    <>
                                        <div className=" flex justify-content-center align-content-center">
                                            <Avatar label={part && auth && part[auth.channel.slackUserId].real_name[0]} style={{ backgroundColor: part && auth && part[auth.channel.slackUserId].color, color: 'black' }} shape="circle" size="large" />
                                            {!collapsed && (
                                                <b className="px-3 py-3">{part && auth && part[auth.channel.slackUserId].real_name}</b>)}
                                        </div>
                                    </>
                                ) : (<div />)}

                            </div>
                        </Link>

                    </div>
                    <MenuItem onClick={() => handleLogout()}>  <span style={{ width: 30 }} className="pi pi-sign-out"></span>{"  " + " Logout"}</MenuItem>

                </Menu>
            </Sidebar>
        </div>
    );
}