import { app, db } from "../firebaseConfig";
import { collection, addDoc, getDocs, doc, getDoc, setDoc, query, where, orderBy, updateDoc, deleteDoc  } from "firebase/firestore";

const dbInstance = collection(db, "users");
const moment = require("moment");

export const addUser = async (data) => {
  try {
    // const docRef = await addDoc(collection(db, "users"), data);
    let uid = data.team.id + data.channel.slackUserId;
    const docRef = await setDoc(doc(db, 'users', uid), {...data});
    await addDoc(collection(db, "logs"), {updatedBy: "-",  createdBy: data.channel.slackUserId, type: "user", status: "success", teamId:data.team.id,  message: "New user <" + data.channel.slackUserId +"> created", timestamp: moment().format("YYYY-MM-DD HH:mm:ss")});
    return docRef.id;
  } catch (error) {
    console.error("Error adding user: ", error);
    await addDoc(collection(db, "logs"), {updatedBy: "-",  createdBy: data.channel.slackUserId, type: "user", status: "error", teamId:data.team.id,  message: error + " in creating user <" +  data.channel.slackUserId + ">" , timestamp: moment().format("YYYY-MM-DD HH:mm:ss")});
   
  }
};

export const getUser = async (id) => {
  const docRef = doc(db, "users", id);
  let user = await getDoc(docRef);
  if (user?.exists()) {
    return user.data();
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }

  return user;
};

export const getUsers = async () => {
  let users = await getDocs(dbInstance).then((data) => {
    return data;
  });
  return users;
};

export const getUsersbyOrgId = async (teamObj) => {
  let first = query(collection(db, "users"),where("team", "==", teamObj),orderBy("userType", 'asc'));
  const querySnapshot = await getDocs(first).then((data) => {
    return data;
  });
  // let users = await getDocs(dbInstance).then((data) => {
  //   return data;
  // });
  return querySnapshot;
};

export const updateUserData = async (data, id, userId, team) => {
  try{
    const dbInstance = doc(db, "users", id);
    let res = await updateDoc(dbInstance, {userData: data});
    await addDoc(collection(db, "logs"), {updatedBy: userId,  createdBy: userId, type: "user", status: "success", teamId:team,  message: "<" + userId + `>'s profile was updated`, timestamp: moment().format("YYYY-MM-DD HH:mm:ss")});
    return res;
  }catch(error){
    console.error("Error updating document: ", error);
      await addDoc(collection(db, "logs"), {updatedBy: userId,  createdBy: userId, type: "user", status: "error", teamId:team,  message: error + " in updating <" +  userId + ">" , timestamp: moment().format("YYYY-MM-DD HH:mm:ss")});  
  }
   

};

export const updateUser = async (data, id, auth, userId) => {


  try{
    const dbInstance = doc(db, "users", id);
    let res = await updateDoc(dbInstance, {userData: data.uData, activeStatus: data.activeStatus, userType: data.userType});
    await addDoc(collection(db, "logs"), {updatedBy: auth.channel.slackUserId,  createdBy: userId, type: "user", status: "success", teamId:auth.team.id,  message: "<" + userId + `>'s profile was updated`, timestamp: moment().format("YYYY-MM-DD HH:mm:ss")});
    return res;
  }catch(error){
    console.error("Error updating document: ", error);
      await addDoc(collection(db, "logs"), {updatedBy: auth.channel.slackUserId,  createdBy: userId, type: "user", status: "error", teamId:auth.team.id,  message: error + " in updating <" +  userId + ">" , timestamp: moment().format("YYYY-MM-DD HH:mm:ss")});  
  }

};

export const deleteUser = async (id, auth, userId) => {

  try{
    let res = await deleteDoc(doc(db, "users", id));
    await addDoc(collection(db, "logs"), {updatedBy: auth.channel.slackUserId,  createdBy: userId, type: "user", status: "success", teamId:auth.team.id,  message: "<" + userId + `>'s profile was delted`, timestamp: moment().format("YYYY-MM-DD HH:mm:ss")});
    return res;
  }catch(error){
    console.error("Error updating document: ", error);
      await addDoc(collection(db, "logs"), {updatedBy: auth.channel.slackUserId,  createdBy: userId, type: "user", status: "error", teamId:auth.team.id,  message: error + " in deleting <" +  userId + ">" , timestamp: moment().format("YYYY-MM-DD HH:mm:ss")});  
  }
};