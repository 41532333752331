import * as React from "react";
import Slack from '../images/slack.png'
import MediaQuery from 'react-responsive'

export default function Header() {
    const scope = ["channels:write channels:read chat:write users:read groups:read users:read.email"];
    return (
        <div className="flex justify-content-center flex-wrap" style={{minHeight:'100vh'}}>
            <div className="flex flex-wrap align-items-center justify-content-center gap-3 mb-4">
                <div className="col-5">
                    <div>
                    <h1 >
                        Before they sold out
                        <br className="hidden lg:inline-block" />
                         readymade gluten

                    </h1>
                    <p className="mb-8">
                        Copper mug try-hard pitchfork pour-over freegan heirloom neutra air
                        plant cold-pressed tacos poke beard tote bag. Heirloom echo park
                        mlkshk tote bag selvage hot chicken authentic tumeric truffaut
                        hexagon try-hard chambray.
                    </p>
                    <a
                        href={`https://slack.com/oauth/v2/authorize?user_scope=${scope}&client_id=${process.env.REACT_APP_CLIENT_ID}&state=login&redirect_uri=${process.env.REACT_APP_SLACK_REDIRECT_URI}`}
                        icon="->"
                        styled
                        button
                    >
                        <img
                            src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
                            srcset="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x"
                            alt="button"
                        />
                    </a>
                    </div>
                </div>
                <div>
                    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                    <MediaQuery minWidth={471}>
                        <img
                            alt="hero"
                            width="400"
                            height="400"
                            src={Slack}
                        />
                        </MediaQuery>
                      
                    </div>
                </div>
            </div>
            <MediaQuery maxWidth={470}>
                        <img
                            alt="hero"
                            width="200"
                            height="200"
                            src={Slack}
                        />
                        </MediaQuery>
        </div>
    );
}
