import React, { useEffect, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import Topbar from "../../components/Topbar";
import InvoicesTable from './InvoicesTable';
import PaymentInfo from './PaymentInfo';
import { CreateCustomer, SearchCustomer, ListInvoices } from '../../api/Stripe';
import { loadStripe } from "@stripe/stripe-js";

const moment = require("moment");

export default function Billing() {
  const savedValue = JSON.parse(window.localStorage.getItem("auth"));
  const [customer, setCustomer] = useState("");
  const [invoices, setInvoices] = useState([]);
  const stripePromise = loadStripe("pk_test_WRr6k7JXHNlcbgVpTDKSHp7p");
  useEffect(() => {
    setUpIntent();
  }, []);

  const setUpIntent = async () => {
    const customerdata = await SearchCustomer(savedValue.team.name);
    setCustomer(customerdata?.data.id);
    const inv = await ListInvoices(customerdata?.data.id);
    let filtered = [];
    await inv.map((data) => {
      let x = {
        createdAt : moment(new Date( data.created *1000)).format("YYYY-MM-DD hh:mm a"),
        number: data.number,
        total: "$ " + String(data.total).slice(0, -2) + ".00",
        status: data.status == "open" ? 'failed' : data.status == "void" || data.status == "uncollectible" ? 'unpaid' : data.status ,
      }
      filtered.push(x);
    })
    setInvoices(filtered)


    if (customerdata.data == "No data") {
      const newCustomer = await CreateCustomer(savedValue.team.name, 'nirmalamanda98@gmail.com');
      setCustomer(newCustomer?.id);


      const inv = await ListInvoices(newCustomer.id);
      let filtered = [];
      await inv.map((data) => {
        let x = {
          createdAt :  moment(new Date( data.created *1000)).format("YYYY-MM-DD hh:mm a"),
          number: data.number,
          total: "$ " + String(data.total).slice(0, -2) + ".00",
          status: data.status == "open" ? 'failed' : data.status == "void" || data.status == "uncollectible" ? 'unpaid' : data.status ,
        }
        filtered.push(x);
      })
      setInvoices(filtered)
    }
  }


  return (
    <>
      <div>
        <div> <Topbar title={"Billing"} /></div>
        <script src="https://js.stripe.com/v3/"></script>
      </div>
      <div className="card mx-5 my-5 px-4 ">
        <TabView>
          <TabPanel header="Payment Information">
            {customer && (
              <PaymentInfo
                stripePromise={stripePromise}
                customer={customer} />
            )}
          </TabPanel>
          <TabPanel header="Invoices">
            <InvoicesTable invoices={invoices} />
          </TabPanel>
        </TabView>
      </div>

    </>

  )
}
