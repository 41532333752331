
import { Card } from 'primereact/card';
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
export default function Page403() {

    
    const navigate = useNavigate();

    return (
        <Card style={{height: '100vh'}}>
                  <div class="flex justify-content-center" >
          {/* <img alt="Card" src={image404} width={'30%'} style={{ zIndex: 15, }} /> */}
          <p style={{ fontSize: 160, fontWeight:800, margin: '2vh', color: '#005CE8'  }}>Forbidden! </p>
          </div>
            <div class="flex justify-content-center" >
                {/* <h1  style={{fontWeight:600, fontSize:40}}>403 - ACCESS DENIED</h1> */}
               

            </div>

            <div class="flex justify-content-center" >
                <h3 style={{ color: 'black' }}>  You don't have permission to view the page that you are looking for. Please contact your organization. </h3>
            </div>

        <div class="my-6 flex justify-content-center" >
        <Button className="px-5" label="GO TO HOMEPAGE" aria-label="Edit" onClick={() => navigate("/templates")} />
        </div>
        </Card>

    );
}