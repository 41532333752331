import { Button } from "primereact/button";
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'

export default function Topbar(props) {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 470px)'
    })
    return (
        // <div style={{backgroundColor: "white", padding: 5, zIndex:1, position: "absolute"}}>
        <>

            <div class="flex flex-row flex-wrap" style={{ backgroundColor: "white" }}>
                <div class="flex-none flex align-items-center justify-content-center font-bold mx-4 px-5 py-0 border-round">
                    <div>
                        <div class="flex flex-column" style={{ padding: 0, margin: 0 }}>
                            <div className="m-0 px-0 py-0" style={{ color: 'black', padding: 0 }}>
                                <h3 style={{ padding: 0, margin: 0 }}>{props.subtitle}</h3>
                                <p style={{ padding: 0, margin: 0, fontWeight: 200, color: "#707EAE" }}>{props.subtitle2}</p>
                            </div>
                            <div className="m-0 px-0 py-0" style={{ color: '#2B3674', padding: 0, margin: 0 }}>
                                <h1 style={{ padding: 0, margin: 0 }}>{props.title}</h1>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="flex-grow-1 flex align-items-center justify-content-center font-bold py-3 border-round">
                    <div>
                    </div>
                </div>
                <div class="flex-none flex align-items-center justify-content-center font-bold m-2 px-5 py-1 border-round">
                    <div class="flex align-items-center   mx-2 my-0">
                        {/* <Button icon="pi pi-search" rounded outlined severity="secondary" aria-label="Bookmark">
                            
                        </Button> */}
                    </div>
                    {isDesktopOrLaptop ? (
                        <div class="flex align-items-center pr-7 mx-2 my-0">
                            <Button icon="pi pi-bell" rounded outlined severity="secondary" aria-label="Bookmark" /></div>
                    ) : (
                        <div class="flex align-items-center pr-2 mx-2 my-0">
                            <Button icon="pi pi-bell" rounded outlined severity="secondary" aria-label="Bookmark" /></div>
                    )}

                    <Link to={'/templates'} style={{ textDecoration: 'none', }}>
                        <Button icon="pi pi-plus" label="New Standup" severity="info" /></Link>

                </div>



            </div>
            {/* </div> */}



        </>


    );
}