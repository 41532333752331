
import React, { useState, useEffect } from "react";
import { ToggleButton } from 'primereact/togglebutton';

const Arr = [
  { value: "Monday", name: "M" },
  { value: "Tuesday", name: "T" },
  { value: "Wednesday", name: "W" },
  { value: "Thursday", name: "T" },
  { value: "Friday", name: "F" },
  { value: "Saturday", name: "S" },
  { value: "Sunday", name: "S" },
];


export default function WeeksArray(props) {
  const disableBtns = props?.disableBtns ? props?.disableBtns : false;
  let daysArrData = props?.dateArrData ? props?.dateArrData : {};
  const [count, setCount] = useState(0);
  const [daysObj, setDaysObj] = useState({});

  // useEffect(() => {
  //   // const daysObj = props?.dateArrData ? props?.dateArrData : {};
  //   // setDaysObj(daysObj);
  //   daysArrData = props?.dateArrData ? props?.dateArrData : {};
  //   setDaysObj(daysArrData);

    
  // }, []);

  useEffect(() => {
    setTimeout(() => {
      daysArrData = props?.dateArrData ? props?.dateArrData : {};
      setDaysObj(daysArrData);
    }, 1000);
}, [count]);

  const handleOnChange = (value, key) => {
    setCount((c) => c + 1);
    props.handleOnChange(value,key);
  };

  return (
    <div key={daysObj} class="flex flex-column w-full">
      {Array(props.points && props.points).fill(0).map((week, index) => (

        <div class="flex align-items-start justify-content-start mt-2 ">{Arr.map((ar) => (
          <div className="mr-4">
            <ToggleButton disabled={disableBtns} checked={daysObj && daysObj[index + 1] &&
              daysObj[index + 1].includes(ar?.value)
              ? true
              : false}
              onLabel={ar.name} offLabel={ar.name} onChange={(e) => handleOnChange(ar?.value, index + 1)} /></div>
        ))}</div>

      ))}
    </div>
  );
}