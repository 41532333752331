

export const sendIntroMessage = async(messageObj) => {
    const token  = process.env.REACT_APP_SLACK_BOT_TOKEN;
    let params = {
      // token: token,
        channel: messageObj.user,
        text: "Hello",
        blocks: JSON.stringify([
          {
            "type": "section",
            "text": {
              "type": "mrkdwn",
              "text": "Hi <@" +messageObj.user+">! :wave:\n <@"+messageObj.addedBy+"> has added you to *"+messageObj.title+ "* and I am here to get you started.:rocket:!\n\n" 
            }
          },
          {
            "type": "section",
            "text": {
              "type": "mrkdwn",
              "text": "I will send you a DM tomorrow at "+messageObj.time+" ("+messageObj.selectedTimezone+") .Your answers will be posted to <#"+messageObj.selectedChannel+">, so all your team members can stay synced."
            }
          },
        ])
      }
    try {
      const res = await fetch("https://slack.com/api/chat.postMessage", {
        method: "POST",
        body: `token=${token}&channel=${params.channel}&text=${params.text}&blocks=${params.blocks}`,

        headers: {
          "Content-Type": "application/x-www-form-urlencoded", 
        },
      }).catch((error) => {
        console.log(error);
      });
      if (!res.ok) {
        throw new Error(`Server error ${res.status}`);
      } else {
    
        const data = await res.json();
        return {
          message: "success",
          data: data
        }
      }
    } catch (error) {
      return {
        message: "error",
        error: error
      }
    }
    }