import {
    collection,
    query,
    where,
    getDocs,
  } from "firebase/firestore";
  import { app, db } from "../firebaseConfig";
  
  const moment = require("moment");

  export const getTodayStatus = async (params,range) => {
    try {
      let startDate = moment(range.startDate).format("YYYY-MM-DD 00:00");
      let endDate = moment(range.endDate).format("YYYY-MM-DD 23:59");
    // let startDate = moment().startOf('day').format("YYYY-MM-DD 00:00");
    // let endDate = moment().endOf('day').format("YYYY-MM-DD 23:59")

      let first = null;

        first = query(collection(db, "statuses"),where("flowId", "==", String(params)),where("createdAt", ">=", startDate),where("createdAt", "<", endDate));


      let statuseslist = await getDocs(first).then((data) => {
        return data;
      });
  
      return statuseslist;
    } catch (error) {
      console.error("Error retreving document: ", error);
    }
  };