import { Card } from 'primereact/card';
import { Badge } from 'primereact/badge';
import { Avatar } from 'primereact/avatar';
import { AvatarGroup } from 'primereact/avatargroup';
import React from 'react';

const colors = [
    "#3FBF81", "#2196F3", "#FFBF66", "#9c27b0", "D2DCE8"
]

const TemplateCard = (props) => {
    return (
        <>
            <Card className='templateCard'>
                <p className="mx-3 my-0">

                    <div class="flex justify-content-between flex-wrap">
                        <div class="flex align-items-center justify-content-center ">
                            <Badge style={{ fontSize:14, }} value={props.badge} severity="contrast" size="large" rounded></Badge>
                        </div>
                        <div class="flex align-items-center justify-content-center ">
                            <AvatarGroup>
                                {props.participants && props.participants.map((participant, index) => (
                                    <>
                                        <Avatar sx={{ height: '70px', width: '70px' }} label={props.participantObj && props.participantObj[participant]?.profile ? props.participantObj[participant]?.profile?.real_name[0] : props.participantObj[participant]?.name[0]}
                                         style={{ backgroundColor: props.participantObj && props.participantObj[participant].color, fontSize:14 , color: '#ffffff' }} shape="circle" size="xlarge" />
                                    </>

                                ))}
                            </AvatarGroup>
                        </div>
                    </div>
                    <h3 style={{fontWeight:600, fontSize:16}}>{props.title} </h3>
                    <p style={{ opacity: 0.5, fontWeight:400, fontSize:14 }}><span style={{ width: 30 }} className="pi pi-clock"></span>{props.datetime}</p>
                    <p style={{ opacity: 0.5, fontWeight:400, fontSize:14 }}><span style={{ width: 30 }} className="pi pi-clocks"/>{props.timezone}</p>
                </p>
            </Card>
        </>
    );
}

export default TemplateCard;