

import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputSwitch } from "primereact/inputswitch";
import { Card } from 'primereact/card';
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import { InputMask } from 'primereact/inputmask';
import { useMediaQuery } from 'react-responsive'
import { Password } from 'primereact/password';
import Visa from '../../images/visa.png';
import Mastercard from '../../images/mastercard.png';
import Amex from '../../images/amex.png';
import { Checkbox } from 'primereact/checkbox';
import CheckoutForm from './Checkout';
import PaymentMethodModal from './PaymentMethodModal';
import { ListPaymentMethods, ListProducts, ListPrice, createSubscription, DeletePaymentMethod, ListSubscriptions, changeSubscription, updateCustomerDefaultPayment, CreateSetupIntent } from '../../api/Stripe';

import { Elements } from "@stripe/react-stripe-js";

export default function PaymentInfo(props) {
    const [view, setView] = useState("package");
    const toast = useRef(null);
    const stripePromise = props.stripePromise;
    const customer = props.customer;
    const [active, setActive] = useState(null);
    const [visible, setVisible] = useState(false);
    const [checked, setChecked] = useState(true);
    const [upgradeInfo, setUpgradeInfo] = useState(null);
    const [secretType, setSecretType] = useState(null);
    const [clientSecret, setClientSecret] = useState(props.clientSecret);
    const [intentId, setIntentId] = useState("");
    const [products, setProducts] = useState([]);
    const [prices, setPrices] = useState([]);
    const [customerCards, setCustomerCards] = useState([]);
    const [defaultCard, setDefaultCard] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 470px)'
    })


    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };
    useEffect(() => {
        setupProducts();
    }, []);


    const setupProducts = async () => {
        const subscriptions = await ListSubscriptions(customer);
        setSubscription(subscriptions);
        const prod = await ListProducts();
        const pric = await ListPrice();
        const payemethods = await ListPaymentMethods(customer);
        setProducts(prod);
        setPrices(pric);
        setCustomerCards(payemethods);

        await prod.map((p, index) => {
            if (subscriptions && p.id == subscriptions.items.data[0].price.product) {
                setActive(index);
            }
        })

        await payemethods.map((p, index) => {
            if (subscriptions && p.id == subscriptions.default_payment_method) {
                setDefaultCard(payemethods[index]);
            }
        })
    }

    const setupIntent = async () => {
    }

    const deleteCard = async (card) => {
        const data = await DeletePaymentMethod(card);
        if (data.id) {
            toast.current.show({ severity: 'info', summary: 'Success', detail: 'Payment method deleted successfully' });
        } else if (data.error) {
            toast.current.show({ severity: 'danger', summary: 'Error', detail: data.error });
        } else {
            toast.current.show({ severity: 'danger', summary: 'Error', detail: 'Something went wrong.' });
        }

        const payemethods = await ListPaymentMethods(customer);
        setCustomerCards(payemethods);
        setDefaultCard(payemethods && payemethods[0]);
    }

    const activatePlan = async (index, data) => {
        setActive(index);
        const subs = await createSubscription(data.default_price, customer);
        setClientSecret(subs.latest_invoice.payment_intent.client_secret);
        setIntentId(subs.id);
        setClientSecret(subs.latest_invoice.payment_intent.client_secret);
        setSecretType("payment");
        setView("checkout");

    }

    const openDialog = async (index, data) => {
        setActive(index);
        setVisible(true);
        setUpgradeInfo(data);
    }

    const updatePlanFromModal = async () => {
        if (upgradeInfo) {
            const subs = await changeSubscription(subscription.items.data[0]?.id, upgradeInfo.default_price);
            if (subs) {
                toast.current.show({ severity: 'info', summary: 'Success', detail: 'Plan changed successfully' });
            }
            setUpgradeInfo(null);
            setVisible(false);
        }
    }

    const changeDefaultCard = async (payment, index) => {
        setDefaultCard(customerCards[index]);
        const pay = await updateCustomerDefaultPayment(customer, subscription.id, payment);
        if (pay) {
            toast.current.show({ severity: 'info', summary: 'Success', detail: 'Default payment method changed successfully' });
        }
    }

    const addNewCard = async () => {
        const subs = await CreateSetupIntent(customer);
        setClientSecret(subs.client_secret);
        setSecretType("newCard");
        setIntentId(subs.id);
        setView("checkout");
    }

    return (
        <>
            <div className="card ">

                <Toast ref={toast}></Toast>
                {view === "package" ? (
                    <>
                        <div class="flex flex-row m-5">
                            Monthly
                            <div class="mx-2"><InputSwitch style={{ color: 'red' }} checked={checked} onChange={(e) => setChecked(e.value)} />
                            </div>
                            Yearly
                        </div>
                        <div class="flex flex-row flex-wrap">
                            {products && products.map((info, index) => (
                                <>
                                    <div style={isDesktopOrLaptop ? { minWidth: '20%' } : { minWidth: '45%', }}>
                                        <div class="align-items-center justify-content-start mx-6 px-4 py-3" style={{ backgroundColor: index === active ? '#E6F4FF' : '', color: '#000766', height: isDesktopOrLaptop ? '40vh' : '20vh' }} >
                                            <h1 style={{ fontWeight: 400 }}>{info.name}</h1>
                                            <div class="flex flex-row" >
                                                <h1 style={{ marginTop: 0 }}>{prices && prices.filter((price) => price.id == info.default_price)[0] &&
                                                prices.filter((price) => price.id == info.default_price)[0].unit_amount_decimal &&
                                                    prices.filter((price) => price.id == info.default_price)[0].unit_amount_decimal.slice(0, -2)} </h1>
                                                {info.name == "Free" && <h1 style={{ marginTop: 0 }}>0</h1>}
                                                <p style={{ color: '#A1A2B2' }}>{" .00 / m"}</p>
                                            </div>
                                            {info.marketing_features.map((feat) => (
                                                <p> <span style={{ width: 30, }} className="pi pi-check"></span>{feat.name}</p>
                                            ))}


                                        </div>
                                        <div class="flex align-items-center justify-content-start mx-6 p-3" style={{ backgroundColor: index === active ? '#E6F4FF' : '', color: '#000766', minWidth: isDesktopOrLaptop ? '20%' : '45%' }}>

                                            {subscription == null ? (<Button style={{ marginBottom: 5 }} outlined label="Choose Plan" severity='info' color="#005CE8" aria-label="Edit" onClick={() => activatePlan(index, info)} />) :
                                                index === active ? (<Button style={{ marginBottom: 5 }} severity='info' label="My Plan" color="#005CE8" aria-label="Edit" onClick={() => openDialog(index, info)} />)
                                                    : index < active ? (<Button style={{ marginBottom: 5 }} outlined severity='info' label="Upgrade" color="#005CE8" aria-label="Edit" onClick={() => openDialog(index, info)} />)
                                                        : (<Button style={{ marginBottom: 5 }} outlined label="Choose Plan" severity='info' color="#005CE8" aria-label="Edit" onClick={() => openDialog(index, info)} />)}

                                        </div>
                                    </div>

                                </>
                            ))}

                        </div>

                        <div className='p-5' >

                            <div class="flex justify-content-start">
                                <Card style={{ width: '100%' }}>
                                    <h4>Update your payment method</h4>
                                    <p>Your plan payment details: </p>
                                    {customerCards.length > 0 && (
                                        customerCards.map((card, index) => (
                                            <div className='flex justify-content-center m-1' >
                                                {defaultCard && card.card.brand === defaultCard.card.brand && card.card === defaultCard.card ? (
                                                    <div className='m-2 p-2 grid' style={{ backgroundColor: "#E9EFFF", border: "1px solid", borderColor: "#1B59F9", borderRadius: 15, width: '100%' }}>
                                                        <div class=" col-2">
                                                            <img alt="Card" src={card.card.brand === "visa" ? Visa : card.card.brand === "mastercard" ? Mastercard : card.card.brand === "amex" ? Amex : null} />


                                                        </div>
                                                        <div class="col-9">
                                                            <b>{card.card.brand + " ending in " + card.card.last4}</b>
                                                            <p>{"Expiry " + card.card.exp_month + ' / ' + card.card.exp_year}</p>
                                                            <Button outlined text severity='secondary' onClick={() => changeDefaultCard(card.id, index)}>Set as default</Button>
                                                            <Button outlined text severity='danger' onClick={() => deleteCard(card.id)}>Delete Card</Button>
                                                        </div>
                                                        <div class="col-1">
                                                            <Checkbox onClick={() => changeDefaultCard(card.id, index)} checked={true}></Checkbox>
                                                        </div>


                                                    </div>
                                                ) : (
                                                    <div className=' m-2 p-2 grid' style={{ border: "1px solid", borderColor: "#E4E7EC", borderRadius: 15, width: '100%' }}>
                                                        <div class="col-2">
                                                            <img alt="Card" src={card.card.brand === "visa" ? Visa : card.card.brand === "mastercard" ? Mastercard : card.card.brand === "amex" ? Amex : null} />

                                                        </div>
                                                        <div class="col-9">
                                                            <b>{card.card.brand + " ending in " + card.card.last4}</b>
                                                            <p>{"Expiry " + card.card.exp_month + ' / ' + card.card.exp_year}</p>
                                                            <Button outlined text severity='secondary' onClick={() => changeDefaultCard(card.id, index)}>Set as default</Button>
                                                            <Button outlined text severity='danger' onClick={() => deleteCard(card.id)}>Delete Card</Button>
                                                        </div>
                                                        <div class="col-1">
                                                            <Checkbox onClick={() => changeDefaultCard(card.id, index)} checked={false}></Checkbox>
                                                        </div>


                                                    </div>
                                                )}

                                            </div>
                                        ))
                                    )}
                                    <div className='flex justify-content-end m-1' >
                                        <Button severity='primary' onClick={() => addNewCard()}>Add new card</Button>
                                    </div>
                                </Card>
                            </div>



                        </div>


                    </>
                )  : view === "checkout" ? (
                    <>
                        {/* {clientSecret && ( */}
                        <Elements options={options} stripe={stripePromise}>
                            <CheckoutForm clientSecret={clientSecret} intentId={intentId} secretType={secretType} setView={setView} />
                        </Elements>
                        {/*   )}  */}
                    </>
                ) : (
                    <div className="card ">Else Payment</div>
                )}

                <PaymentMethodModal isDesktopOrLaptop={isDesktopOrLaptop} visible={visible} setVisible={setVisible} customerCards={customerCards} defaultCard={defaultCard} addNewCard={addNewCard}
                    changeDefaultCard={changeDefaultCard} Visa={Visa} Mastercard={Mastercard} Amex={Amex} updatePlanFromModal={updatePlanFromModal} />
            </div>



        </>
    );
}
