import { Card } from "primereact/card";
import { Avatar } from "primereact/avatar";
import { useMediaQuery } from 'react-responsive'
const Members = [
    { name: "Brooklyn Simmons", streak: 19 },
    { name: "Ralph Edwards", streak: 13 },
    { name: "Arlene McCoy", streak: 9 },
]

function Streaks(props) {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 470px)'
    })
    return (

        <Card title={"User streak leaderboard "}>
            {props.streaks && props.streaks.map((member, index) => {
                return (
                    <div key={member.name} className="grid">
                        <div className="flex col-2 align-content-center justify-content-center" style={!isDesktopOrLaptop ? { marginTop: 7} : {}}>
                            <Avatar  label={props?.participantObj.length && props?.participantObj[member.id]?.profile ? props?.participantObj[member.id]?.profile?.real_name[0] : props?.participantObj[member.id]?.real_name[0]} 
                            style={{ backgroundColor:  props.participantObj && props.participantObj[member.id].color, color: '#ffffff', marginTop: 10 }} shape="circle" size= "large" />
                        </div>
                        <div className="flex-col col-7 align-content-center ">
                            <h4 style={{ marginTop: 5 , marginBottom: 5 }}>{props?.participantObj.length && props?.participantObj[member.id]?.profile ? props?.participantObj[member.id]?.profile?.real_name : props?.participantObj[member.id]?.real_name}</h4>
                            <p style={{ color: '#A098AE', marginTop: 0 }}>{member.count + " consecutive reports"}</p>
                        </div>
                        <div className="flex col-3 align-content-center  justify-content-center  ">
                            {index === 0 ? (
                                <p style={{ fontSize: 50, margin: 0 }}>🔥</p>
                            ) : index === 1 ? (
                                <p style={{ fontSize: 40, margin: 6 }}>🔥</p>
                            ) : (
                                <p style={{ fontSize: 30, margin: 12 }}>🔥</p>
                            )}

                        </div>
                    </div>
                )
            })}
        </Card>
    );
}

export default Streaks;