import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Meeting from '../images/meeting.png';
import Retro from '../images/retro.png';
import Feedback from '../images/feedback.png';
import Daily from '../images/daily.png';
// import Icon from '../images/Icon.png';
import { Button } from "primereact/button";
import Topbar from "../components/Topbar";
import { Link } from 'react-router-dom';
import { getPresets } from "../api/Presets";
import { Card } from 'primereact/card';
import { ProgressSpinner } from 'primereact/progressspinner';
import { flowsPresentById } from "../api/Flows";
import { getChannels } from "../api/Channels";
import { getUser } from "../api/Users";

export default function Dashboard() {
    const navigate = useNavigate();
    // const [visible, setVisible] = useState(true);
    const [templates, setTemplates] = useState([]);
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(false);
    const part = JSON.parse(window.localStorage.getItem("participantList"));
    const chan = JSON.parse(window.localStorage.getItem("channelList"));
    const auth = JSON.parse(window.localStorage.getItem("auth"));

    useEffect(() => {
        getTemplates(auth);
        setLoading(true);

    }, []);

    const getTemplates = async (auth) => {

        // const flow = await flowsPresentById(JSON.parse(window.localStorage.getItem("auth"))?.team.id);
        // if(flow == true ){
        //     if(window.localStorage.getItem("firstLoad") && window.localStorage.getItem("firstLoad") == "true"){
        //         navigate("/workflows");
        //     }else{

        //     }
        // }
        
        const response = await getChannels(JSON.stringify({ token: auth.token }));
        let presets = await getPresets();
        setTemplates(
            presets?.docs?.map((item) => {
                return { ...item.data(), id: item.id };
            })
        );

        const user = await getUser(auth.team.id + auth.channel.slackUserId);
        setUserData(user);
        setLoading(false);
    };

    const ImgSelector = (key) => {
        if (key == "meeting_notes") {
            return Meeting
        } else if (key == "daily_standup") {
            return Daily
        } else if (key == "team_feedback") {
            return Feedback
        } else if (key == "retrospective") {
            return Retro
        } else {
            return null;
        }
    }


    const footer = (id) => (
        <>

            <div className="flex  justify-content-end" >
                {userData && userData.userType == 'admin' ? (
                     <Link to={'/new-template/' + id} style={{ textDecoration: 'none', }}>
                    <Button icon="pi pi-arrow-right" rounded text severity="secondary" aria-label="Edit" /></Link>
                ) : (
                    <Button icon="pi pi-arrow-right" rounded text severity="secondary" aria-label="Edit" />
                )}
               
            </div>

        </>
    );
    return (
        <>
            <div>
                <div> <Topbar title={"Templates"} subtitle={part && auth ? "👋 Hey, " + part[auth.channel.slackUserId].name : "👋 Hello there "} /></div>
            </div>
            <div>
                {/* {visible ? (
                    <div style={{ backgroundColor: '#4339F2' }}>
                        <div class="flex flex-row py-2">
                            <div className="col-2 flex justify-content-center">
                                <img alt="Card" src={Icon} width={'50rem'} height={'50rem'} />
                            </div>
                            <div className="col-9 text-white">
                                <h3 style={{ marginBottom: 0 }}>This is a beta version </h3>
                                <p style={{ marginTop: 0 }}> This version is a test version of the Pulse plattform and all of it’s features. It is not launched yet but stay tuned! </p>
                            </div>
                            <div className="col-1 flex justify-content-end text-white">
                                <button type="submit" style={{ backgroundColor: "#4339F2", color: 'white', border: 'none' }} onClick={() => setVisible(false)} >
                                    <h3 style={{ marginTop: 0 }}><span style={{ width: 30, }} className="pi pi-times"></span></h3>
                                </button>
                                {/* <span id="close" onclick="document.getElementById('modalWindow').style.display='none';">x</span> 
                                {/* </Button> 
                            </div>
                        </div>
                    </div>
                ) : null} */}

            </div>

            {templates.length > 0 ? (
                // <div class="flex justify-content-center flex-wrap" >
                <div class="grid m-5">
                    {templates.map((template) => (
                        // <div class="flex justify-items-start justify-content-center font-bold m-5 border-round" style={{ minWidth: 200, }} key={template.key}>
                        //     <div className="card flex justify-content-center">
                        <div class="col-12 md:col-6 lg:col-3 p-2">
                            {userData && userData.userType == 'admin' ? (
                                <Link to={'/new-template/' + template.id} style={{ textDecoration: 'none', }}>
                                    <Card title={template.title} footer={footer(template.id)} header={<img alt="Card" src={ImgSelector(template.key)} />} >
                                        <p className="m-0" style={{ minHeight: 60 }}>
                                            {template.description}
                                        </p>
                                    </Card>
                                </Link>
                            ) : (
                                <Card title={template.title} footer={footer(template.id)} header={<img alt="Card" src={ImgSelector(template.key)} />} >
                                    <p className="m-0" style={{ minHeight: 60 }}>
                                        {template.description}
                                    </p>
                                </Card>
                            )}

                        </div>

                        // </div>
                    ))}
                    {/* </div> */}
                </div>
            ) : (
                <div class="flex align-items-center justify-content-center flex-wrap" >
                    <h1><ProgressSpinner /> </h1>
                </div>

            )}



        </>

    );
}