import axios from "axios";

// export const getChannels = async(body) => {
//     const { token } = JSON.parse(body);
//   const options = {
//     method: "POST",
//     url: `https://slack.com/api/conversations.list?types=public_channel%2Cprivate_channel&pretty=1`, 
//     headers: { 'Content-type' : 'application/x-www-form-urlencoded',  'Access-Control-Allow-Origin': '*', Authorization: `Bearer ${token}` } ,
//       };
//   try {
//     let response = await axios(options);
//     return {
//       message: "success",
//       data: response?.data
//     }
//   } catch (err) {
//       return {
//           message: "error",
//           error: err
//         }
//   }
// }

export const getChannels = async(body) => {
  const { token } = JSON.parse(body);
  try {
    const res = await fetch("https://slack.com/api/conversations.list?types=public_channel%2Cprivate_channel&pretty=1", {
      method: "POST",
      body: `token=${token}`, // body data type must match "Content-Type" header
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }).catch((error) => {
      console.log(error);
    });
    if (!res.ok) {
      throw new Error(`Server error ${res.status}`);
    } else {
  
      const data = await res.json();
      return {
        message: "success",
        data: data
      }
    }
  } catch (error) {
    return {
      message: "error",
      error: error
    }
  }
  }