import { app, db } from "../firebaseConfig";
import { collection, addDoc, getDocs, doc, getDoc, setDoc, query, where, orderBy, updateDoc, deleteDoc  } from "firebase/firestore";

const dbInstance = collection(db, "organizations");
const moment = require("moment");

export const addOrganization = async (data, formData) => {
  try {
    // const docRef = await addDoc(collection(db, "users"), data);
    let uid = data.team.id;
    const docRef = await setDoc(doc(db, 'organizations', uid), {adminData: formData, adminId:data.channel.slackUserId,  activeStatus: "pending", organization: data.team});
    await addDoc(collection(db, "logs"), {updatedBy: "-",  createdBy: data.channel.slackUserId, type: "organizaion", status: "success", teamId:data.team.id,  message: "New organization <" + data.team.name +"> created", timestamp: moment().format("YYYY-MM-DD HH:mm:ss")});
    return docRef;
  } catch (error) {
    console.error("Error adding user: ", error);
    await addDoc(collection(db, "logs"), {updatedBy: "-",  createdBy: data.channel.slackUserId, type: "organizaion", status: "error", teamId:data.team.id,  message: error + " in creating organization <" +  data.team.name + ">" , timestamp: moment().format("YYYY-MM-DD HH:mm:ss")});
   
  }
};

export const getOrganization = async (id) => {
  const docRef = doc(db, "organizations", id);
  let org = await getDoc(docRef);
  if (org?.exists()) {
    return org.data();
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
    return null;
  }

//   return org;
};

export const getUsers = async () => {
  let users = await getDocs(dbInstance).then((data) => {
    return data;
  });
  return users;
};

// export const getUsersbyOrgId = async (teamObj) => {
//   let first = query(collection(db, "users"),where("team", "==", teamObj),orderBy("userType", 'asc'));
//   const querySnapshot = await getDocs(first).then((data) => {
//     return data;
//   });
//   // let users = await getDocs(dbInstance).then((data) => {
//   //   return data;
//   // });
//   return querySnapshot;
// };

// export const updateOrgData = async (data, id, userId, team) => {
//   try{
//     const dbInstance = doc(db, "organizations", id);
//     let res = await updateDoc(dbInstance, {adminData: data.adminData,  activeStatus: data.activeStatus,});
//     await addDoc(collection(db, "logs"), {updatedBy: userId,  createdBy: userId, type: "user", status: "success", teamId:team,  message: "<" + userId + `>'s profile was updated`, timestamp: moment().format("YYYY-MM-DD HH:mm:ss")});
//     return res;
//   }catch(error){
//     console.error("Error updating document: ", error);
//       await addDoc(collection(db, "logs"), {updatedBy: userId,  createdBy: userId, type: "user", status: "error", teamId:team,  message: error + " in updating <" +  userId + ">" , timestamp: moment().format("YYYY-MM-DD HH:mm:ss")});  
//   }
   

// };

export const updateOrg = async (data, id, auth, userId) => {


  try{
    const dbInstance = doc(db, "organizations", id);
    let res = await updateDoc(dbInstance, {adminData: data.adminData, activeStatus: data.activeStatus});
    await addDoc(collection(db, "logs"), {updatedBy: auth.channel.slackUserId,  createdBy: userId, type: "user", status: "success", teamId:auth.team.id,  message: "<" + userId + `>' updated organization details`, timestamp: moment().format("YYYY-MM-DD HH:mm:ss")});
    return res;
  }catch(error){
    console.error("Error updating document: ", error);
      await addDoc(collection(db, "logs"), {updatedBy: auth.channel.slackUserId,  createdBy: userId, type: "user", status: "error", teamId:auth.team.id,  message: error + " in updating organization details <" +  userId + ">" , timestamp: moment().format("YYYY-MM-DD HH:mm:ss")});  
  }

};

// export const deleteUser = async (id, auth, userId) => {

//   try{
//     let res = await deleteDoc(doc(db, "users", id));
//     await addDoc(collection(db, "logs"), {updatedBy: auth.channel.slackUserId,  createdBy: userId, type: "user", status: "success", teamId:auth.team.id,  message: "<" + userId + `>'s profile was delted`, timestamp: moment().format("YYYY-MM-DD HH:mm:ss")});
//     return res;
//   }catch(error){
//     console.error("Error updating document: ", error);
//       await addDoc(collection(db, "logs"), {updatedBy: auth.channel.slackUserId,  createdBy: userId, type: "user", status: "error", teamId:auth.team.id,  message: error + " in deleting <" +  userId + ">" , timestamp: moment().format("YYYY-MM-DD HH:mm:ss")});  
//   }
// };