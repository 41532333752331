import { Outlet, useOutlet, Navigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import SidebarMobile from "../components/SidebarMobile";
import SlackSignIn from "./SlackSignIn";
import Page403 from "./Common/Page403";
import Approval from "./Approval";
import { getUser } from "../api/Users";
import { getOrganization } from "../api/Organizations";
import { useState, useEffect } from "react";
import Icon from '../images/Icon.png';
import { ProgressSpinner } from 'primereact/progressspinner';
import MediaQuery from 'react-responsive'
import { Button } from 'primereact/button';
import Logo2 from '../images/logo2.png';


const Layout = () => {

    const [authObj, setAuthObj] = useState({});
    const [user, setUser] = useState({});
    const [organization, setOrganization] = useState({});
    const [visible, setVisible] = useState(true);
    const [collapsed, setCollapsed] = useState(false);
    const [loading, setLoading] = useState(false);
    const outlet = useOutlet()
    useEffect(() => {
        setTimeout(() => {
            setAuthObj(JSON.parse(localStorage.getItem("auth")) ? JSON.parse(localStorage.getItem("auth")) : null)
            getTempalte(JSON.parse(localStorage.getItem("auth")));
        }, 1000);
    }, []);


    const getTempalte = async (params) => {
        if (params && params != {}) {
            setLoading(true);
            const org = await getOrganization(params.team.id);
            const userD = await getUser(params.team.id + params.channel.slackUserId);
            // const hours = 1; // to clear the localStorage after 1 hour
            // // (if someone want to clear after 8hrs simply change hours=8)
            // const now = new Date().getTime();
            // const setupTime = localStorage.getItem('setupTime');
            // if (setupTime == null) {
            //     localStorage.setItem('setupTime', now)
            // } else {
            //     if (now - setupTime > hours * 60 * 60 * 1000) {
            //         localStorage.clear()
            //         localStorage.setItem('setupTime', now);
            //     }
            // }
            setOrganization(org);
            setUser(userD);
            setLoading(false);
        }

    };


    return (
        <div>
            {console.log("organization", organization)}
            {loading ? (
                <div class="flex align-items-center justify-content-center flex-wrap" >
                    <h1><ProgressSpinner /> </h1>
                </div>
            ) : authObj == null ? (
                <SlackSignIn />
            ) : user.activeStatus == false ? (
                <Page403 />
            ) : authObj && organization == null ? (
                <Approval status="null" />
            ) : authObj && organization && organization.activeStatus == "pending" ? (
                <Approval status="pending" />
            ) : !loading && outlet ? (
                <>
                    <MediaQuery minWidth={471}>
                        <div class="flex overflow-auto">
                            {/* <div class="flex font-bold m-0 px-0 py-0 border-round overflow-hidden" style={{
                        // maxHeight: '70vh',
                        top: 0
                    }}> */}
                            <div class="flex sticky top-0">
                                <Sidebar collapsed={collapsed} setCollapsed={() => setCollapsed(!collapsed)} />
                            </div>
                            <div className="fixedSidebar">
                                <Sidebar collapsed={collapsed} setCollapsed={() => setCollapsed(!collapsed)} />
                            </div>
                            {/* </div> */}
                            <div class="flex-grow-1 flex-row font-bold border-round">

                                <div>
                                    {visible ? (
                                        <div style={{ backgroundColor: '#4339F2' }}>
                                            <div class="flex flex-row py-2 ">
                                                <div className="col-1 flex align-content-center justify-content-center">
                                                    <img alt="Card" src={Icon} width={'45rem'} height={'45rem'} />
                                                </div>
                                                <div className="col-10 text-white">
                                                    <h3 style={{ margin: 0 }}>This is a beta version </h3>
                                                    <p style={{ margin: 0 }}> This version is a test version of the Pulse plattform and all of it’s features. It is not launched yet but stay tuned! </p>
                                                </div>
                                                <div className="col-1 px-5 flex align-content-center justify-content-end text-white">
                                                    <button className="justify-content-center" type="submit" style={{ backgroundColor: "#4339F2", color: 'white', border: 'none' }} onClick={() => setVisible(false)} >
                                                        <h3 ><span style={{ width: 30, }} className="pi pi-times"></span></h3>
                                                    </button>
                                                    {/* <span id="close" onclick="document.getElementById('modalWindow').style.display='none';">x</span> */}
                                                    {/* </Button> */}
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    <div>{outlet || <ProgressSpinner />}</div>
                                </div>
                            </div>
                        </div>
                    </MediaQuery>
                    <MediaQuery maxWidth={470} >
                        <div class="overflow-hidden">
                            <div className="flex align-content-start justify-content-start" style={{ backgroundColor: '#FFFFFF' }}>

                                <div className="flex align-content-start justify-content-center mx-2">
                                    <Button
                                        onClick={() => setCollapsed(!collapsed)}

                                        link
                                        style={{
                                            textDecoration: 'none',
                                        }}
                                    >
                                        <div>
                                            <i className="pi pi-bars" style={{ fontSize: '1rem' }}></i>
                                        </div></Button>
                                </div>

                                <img alt="Card" src={Logo2} width={'35%'} style={{ zIndex: 15 }} />

                            </div>
                            <div>
                                {visible ? (
                                    <div style={{ backgroundColor: '#4339F2' }} >
                                        <div class="flex flex-row py-2 ">
                                            <div className="col-2 flex align-content-center justify-content-center">
                                                <img alt="Card" src={Icon} width={'45rem'} height={'45rem'} />
                                            </div>
                                            <div className="col-9 text-white">
                                                <h3 style={{ margin: 0 }}>This is a beta version </h3>
                                                <p style={{ margin: 0 }}> This version is a test version of the Pulse plattform and all of it’s features. It is not launched yet but stay tuned! </p>
                                            </div>
                                            <div className="col-1 flex align-content-center justify-content-end text-white">
                                                <button className="justify-content-center" type="submit" style={{ backgroundColor: "#4339F2", color: 'white', border: 'none' }} onClick={() => setVisible(false)} >
                                                    <h3 ><span style={{ width: 15, }} className="pi pi-times"></span></h3>
                                                </button>
                                                {/* <span id="close" onclick="document.getElementById('modalWindow').style.display='none';">x</span> */}

                                            </div>
                                        </div>
                                    </div>
                                ) : <div />}
                            </div>
                            <div class="">
                                <div class="sticky top-0">

                                    {collapsed && (
                                        <SidebarMobile collapsed={false} setCollapsed={() => setCollapsed(!collapsed)} />
                                    )}

                                    {!collapsed && (
                                        <div>{outlet || <ProgressSpinner />}</div>
                                    )}

                                </div>

                            </div>

                        </div>
                    </MediaQuery>
                </>
            ) : organization.activeStatus == "active" && authObj && outlet == null ? (
                <Navigate to='/workflows' />
            ) : (
                <div class="flex align-items-center justify-content-center flex-wrap" >
                    <h1><ProgressSpinner /> </h1>
                </div>
            )}









        </div>
    )
};

export default Layout;