import axios from "axios";

// export const getParticipants = async(body) => {

//     // const { code } = JSON.parse(req.body);
//     const { token } = JSON.parse(body);
//     const options = {
//         method: "GET",
//         url: `https://slack.com/api/users.list`,
//         data: `token=${token}`
//         // headers: { 'Content-type' : 'application/x-www-form-urlencoded',  'Access-Control-Allow-Origin': '*', Authorization: `Bearer ${token}` } ,
//       };
//     try {
//       let response = await axios(options);
//       return {
//         message: "success",
//         data: response?.data
//       }
//     } catch (err) {
//         return {
//             message: "error",
//             error: err
//           }
//     }

// }

export const getParticipants = async(body) => {
  const { token } = JSON.parse(body);
  try {
    const res = await fetch("https://slack.com/api/users.list", {
      method: "POST",
      body: `token=${token}`, // body data type must match "Content-Type" header
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }).catch((error) => {
      console.log(error);
    });
    if (!res.ok) {
      throw new Error(`Server error ${res.status}`);
    } else {
  
      const data = await res.json();
      return {
        message: "success",
        data: data
      }
    }
  } catch (error) {
    return {
      message: "error",
      error: error
    }
  }
  }

  export const inviteUser = async(tkn, mail, channels, team ) => {
    // const { token } = JSON.parse(tkn);
    // const { email } = JSON.parse(mail);
    // const { channel_ids } = JSON.parse(channels);
    // const { team_id } = JSON.parse(team);
    // let params = {
    //   token: JSON.parse(tkn),
    //   mail: JSON.parse(mail),
    //   channels: JSON.parse(channels),
    //   team: JSON.parse(team)
    // }
    const { token } = JSON.parse(tkn);
    let params = {
      email: mail,
      channel_ids: channels,
      team_id: team
    }
    try {
      const res = await fetch("https://slack.com/api/admin.users.invite", {
        method: "POST",
        params: JSON.stringify(params),
        body: `token=${token}`, 
        // body: JSON.stringify(params),
        // body: `token=${token}, email=${email}, channel_ids=${channel_ids}, team_id=${team_id}`, // body data type must match "Content-Type" header
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).catch((error) => {
        console.log(error);
      });
      if (!res.ok) {
        throw new Error(`Server error ${res.status}`);
      } else {
    
        const data = await res.json();
        return {
          message: "success",
          data: data
        }
      }
    } catch (error) {
      return {
        message: "error",
        error: error
      }
    }
    }